import { useMemo } from 'react';

export default (t) => useMemo(() => ({
  id: {
    name: t('positionIdentifier'),
    type: 'number',
    property: true,
  },
  latitude: {
    name: t('positionLatitude'),
    type: 'number',
    property: true,
  },
  longitude: {
    name: t('positionLongitude'),
    type: 'number',
    property: true,
  },
  speed: {
    name: t('positionSpeed'),
    type: 'number',
    dataType: 'speed',
    property: true,
  },
  course: {
    name: t('positionCourse'),
    type: 'number',
    property: true,
  },
  fixTime: {
    name: t('positionFixTime'),
    type: 'string',
    property: true,
  },
  distance: {
    name: t('positionDistance'),
    type: 'number',
    dataType: 'distance',
  },
  totalDistance: {
    name: t('deviceTotalDistance'),
    type: 'number',
    dataType: 'distance',
  },
  address: {
    name: t('positionAddress'),
    type: 'string',
    property: true,
  },
  obdOdometer: {
    name: t('obdOdometer'),
    type: 'number',
    dataType: 'distance',
  },
  obdCadence: {
    name: t('obdCadence'),
    type: 'number',
    dataType: 'string',
  },
  obdTorque: {
    name: t('obdTorque'),
    type: 'number',
    dataType: 'string',
  },
  obdMotorTemperature: {
    name: t('obdMotorTemperature'),
    type: 'number',
    dataType: 'string',
  },
  obdCellTemperature: {
    name: t('obdCellTemperature'),
    type: 'number',
    dataType: 'string',
  },
  obdSoc: {
    name: t('obdSoc'),
    type: 'number',
    dataType: 'string',
  },
  driverId: {
    name: t('positionDriverId'),
    type: 'string',
  },
  driverName: {
    name: t('positionDriver'),
    type: 'string',
  },
}), [t]);
