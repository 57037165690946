import React, { useState, useEffect } from 'react';
import {
  Alert, Card,
  useTheme, useMediaQuery,
} from '@mui/material';
import {
  CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis,
} from 'recharts';
import ReportFilter from './components/ReportFilter';
import { formatTime } from '../common/util/formatter';
import { useTranslation } from '../common/components/LocalizationProvider';
import PageLayout from '../common/components/PageLayout';
import ReportsMenu from './components/ReportsMenu';
import useChartAttributes from '../common/attributes/useChartAttributes';
import { useCatch } from '../reactHelper';
import { useAttributePreference, usePreference } from '../common/util/preferences';
import {
  altitudeFromMeters, distanceFromMeters, speedFromKnots, volumeFromLiters,
} from '../common/util/converter';
import useReportStyles from './common/useReportStyles';
import SelectField from '../common/components/SelectField';

const CustomTooltip = ({ active, payload, label, additionalValue, t }) => {
  const classes = useReportStyles();
  if (active && payload && payload.length) {
    if (label) {
      const [date, time, twelveClock] = label.split(' ');
      return (
        <Card elevation={3} className={classes.card}>
          <div className="custom-tooltip">
            <p className="label">{`${t('sharedDate')}: ${date}`}</p>
            {twelveClock ? (
              <p className="label">{`${t('sharedTime')}: ${time} ${twelveClock}`}</p>
            ) : (
              <p className="label">{`${t('sharedTime')}: ${time}`}</p>
            )}
            <p className="label">{`${additionalValue.title}: ${payload[0].value} ${additionalValue.unit}`}</p>
          </div>
        </Card>
      );
    }
    return (
      <div className="custom-tooltip">
        <p className="label">{`${additionalValue.title}: ${payload[0].value} ${additionalValue.unit}`}</p>
      </div>
    );
  }
  return null;
};

const calcCoordinate = (items, type, desktop, tablet) => {
  const values = items.map((it) => it[type]);
  const interval = (desktop, tablet) => {
    if (desktop) return 5; // when desktop -> set X Axis interval to 5
    if (tablet) return 3; // when tablet -> set X Axis interval to 3
    return 5; // default (desktop)
  };

  const xAxisInterval = Math.round(items.length / interval(desktop, tablet));
  let minValue = null; // set Y axis origin min value
  const maxValue = Math.max(...values); // set Y axis origin max value
  if (type === 'totalDistance' || type === 'obdOdometer') {
    minValue = Math.min(...values);
  } else if (type === 'speed') {
    minValue = 0;
  }
  return { minValue, maxValue, xAxisInterval };
};

const ChartReportPage = () => {
  const classes = useReportStyles();
  const t = useTranslation();
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('md'));
  const tablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const chartAttributes = useChartAttributes(t); // source from: [usePositionAttributes.js]

  const hiddenPositionColumns = useAttributePreference('report.routeHiddenPositionColumns', '').split(',');
  const displayPositionAttributes = Object.keys(chartAttributes)
    .filter((attributeKey) => hiddenPositionColumns.indexOf(attributeKey) === -1 && chartAttributes[attributeKey].type === 'number')
    .reduce((obj, key) => Object.assign(obj, { [key]: chartAttributes[key] }), {});

  const distanceUnit = useAttributePreference('distanceUnit');
  const altitudeUnit = useAttributePreference('altitudeUnit');
  const speedUnit = useAttributePreference('speedUnit');
  const volumeUnit = useAttributePreference('volumeUnit');
  const hours12 = usePreference('twelveHourFormat');

  const [items, setItems] = useState([]);
  const [errMsg, setErrMsg] = useState('');
  // const [types, setTypes] = useState(['speed']);
  const [type, setType] = useState('speed');
  const [unit, setUnit] = useState({});
  const [visible, setIsVisible] = React.useState(false);
  const { minValue, maxValue, xAxisInterval } = calcCoordinate(items, type, desktop, tablet);
  const handleType = (type) => {
    let chartType = null;
    let chartUnit = null;
    switch (type) {
      case 'speed':
        chartType = t('positionSpeed');
        if (speedUnit === 'kn') chartUnit = t('sharedKn');
        else if (speedUnit === 'kmh') chartUnit = t('sharedKmh');
        else if (speedUnit === 'mph') chartUnit = t('sharedMph');
        break;
      case 'altitude':
        chartUnit = altitudeUnit;
        break;
      case 'distance':
        chartUnit = distanceUnit;
        break;
      case 'totalDistance':
        chartType = t('deviceTotalDistance');
        if (distanceUnit === 'km') chartUnit = t('sharedKm');
        else if (distanceUnit === 'mi') chartUnit = t('sharedMi');
        else if (distanceUnit === 'nmi') chartUnit = t('sharedNmi');
        break;
      case 'odometer':
        chartType = t('deviceTotalDistance');
        if (distanceUnit === 'km') chartUnit = t('sharedKm');
        else if (distanceUnit === 'mi') chartUnit = t('sharedMi');
        else if (distanceUnit === 'nmi') chartUnit = t('sharedNmi');
        break;
      case 'obdOdometer':
        chartType = t('obdOdometer');
        if (distanceUnit === 'km') chartUnit = t('sharedKm');
        else if (distanceUnit === 'mi') chartUnit = t('sharedMi');
        else if (distanceUnit === 'nmi') chartUnit = t('sharedNmi');
        break;
      case 'volume':
        chartUnit = volumeUnit;
        break;
      default:
        chartUnit = speedUnit;
        break;
    }
    setUnit({
      title: chartType,
      unit: chartUnit,
    });
  };
  const handleValidate = (from, to) => {
    // check page period select
    let validate = true;
    const oneDay = 1000 * 60 * 60 * 24; // One day Time in ms (milliseconds)
    const fromDate = new Date(from);
    const toDate = new Date(to);
    const dateDelta = (toDate - fromDate) / oneDay;
    if (dateDelta > 31) {
      setErrMsg(t('periodCustomize') + t('errorMessageType1')); // over 30 days
      validate = false;
    } else if (dateDelta < 0) {
      setErrMsg(t('periodCustomize') + t('errorMessageType2')); // start_date is later than end_date
      validate = false;
    }
    return validate;
  };
  const handleSubmit = useCatch(async ({ deviceId, from, to }) => {
    const validate = handleValidate(from, to);
    const query = new URLSearchParams({ deviceId, from, to });
    if (validate) {
      const response = await fetch(`/api/reports/route?${query.toString()}`, {
        headers: { Accept: 'application/json' },
      });
      if (response.ok) {
        const positions = await response.json();
        const keySet = new Set();
        const keyList = [];
        if (positions.length > 1) {
          const formattedPositions = positions.map((position) => {
            const data = { ...position, ...position.attributes };
            const formatted = {};
            formatted.fixTime = formatTime(position.fixTime, 'minutes', hours12);
            Object.keys(data).filter((key) => !['id', 'deviceId'].includes(key)).forEach((key) => {
              const value = data[key];
              if (typeof value === 'number') {
                keySet.add(key);
                const definition = chartAttributes[key] || {};
                switch (definition.dataType) {
                  case 'speed':
                    formatted[key] = speedFromKnots(value, speedUnit).toFixed(2);
                    break;
                  case 'altitude':
                    formatted[key] = altitudeFromMeters(value, altitudeUnit).toFixed(2);
                    break;
                  case 'distance':
                    formatted[key] = distanceFromMeters(value, distanceUnit).toFixed(2);
                    break;
                  case 'volume':
                    formatted[key] = volumeFromLiters(value, volumeUnit).toFixed(2);
                    break;
                  case 'hours':
                    formatted[key] = (value / 1000).toFixed(2);
                    break;
                  default:
                    formatted[key] = value;
                    break;
                }
              }
            });
            return formatted;
          });
          Object.keys(chartAttributes).forEach((key) => {
            if (keySet.has(key)) {
              keyList.push(key);
              keySet.delete(key);
            }
          });
          const filteredItems = formattedPositions.filter((it) => it[type] !== undefined);
          setItems(filteredItems);
        }
        if (positions.length < 1) {
          setItems([]);
          setErrMsg(t('sharedNoDataWithType', { typeName: t('reportChartRecords') }));
          setIsVisible(true);
          setTimeout(() => {
            setIsVisible(false);
          }, 3000);
        }
      } else {
        throw Error(await response.text());
      }
    } else {
      setItems([]);
      setIsVisible(true);
      setTimeout(() => {
        setIsVisible(false);
      }, 3000);
    }
  });

  useEffect(() => {
    handleType(type);
  }, [type]);
  return (
    <PageLayout menu={<ReportsMenu />} breadcrumbs={['reportTitle', 'reportChart']}>
      <div className={classes.header}>
        <ReportFilter handleSubmit={handleSubmit} selectedColumns={type} showOnly>
          <div className={classes.filterItem}>
            <SelectField
              fullWidth
              color="filledTextFieldBlue"
              label={t('reportChartType')}
              value={type}
              onChange={(e) => setType(e.target.value)}
              disabled={!Object.keys(displayPositionAttributes).length}
              data={Object.keys(displayPositionAttributes)}
              keyGetter={(it) => it}
              titleGetter={(it) => displayPositionAttributes[it]?.name || it}
              emptyValue={null}
            />
          </div>
        </ReportFilter>
      </div>
      {items.length > 0 ? (
        <div className={classes.chart}>
          <ResponsiveContainer>
            <LineChart
              data={items}
              margin={{
                top: 10, right: 40, left: 0, bottom: 10,
              }}
            >
              <XAxis
                dataKey="fixTime"
                interval={xAxisInterval}
                tick={mobile ? null : true}
                tickSize={10}
                tickFormatter={(value) => {
                  const [datetime, minutes, divide] = value.split(' ');
                  const convertedTime = `${datetime} ${minutes} ${divide || ''}`;
                  return convertedTime;
                }}
              />
              <YAxis tickCount={6} type="number" tickFormatter={(value) => parseInt(value.toFixed(2), 10)} domain={[minValue, maxValue]} />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip content={<CustomTooltip additionalValue={unit} t={t} />} />
              <Line type="monotone" dataKey={type} stroke="#56e398" strokeWidth={2} dot={false} />
            </LineChart>
          </ResponsiveContainer>
        </div>
      ) : (visible && <Alert severity="error">{errMsg}</Alert>)}
    </PageLayout>
  );
};

export default ChartReportPage;
