import React from 'react';
import { useSelector } from 'react-redux';
import {
  Divider, Drawer, IconButton, List, Toolbar, Typography,
  ListItem, ListItemText,
} from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from '../common/components/LocalizationProvider';
import { useAttributePreference } from '../common/util/preferences';
import { formatTemperature, formatPercentage, formatDistance } from '../common/util/formatter';

const useStyles = makeStyles((theme) => ({
  listContainer: {
    overflow: 'auto',
    height: 'inherit',
  },
  listBlock: {
    color: theme.palette.mapSidebar.text.default,
    backgroundColor: theme.palette.mapSidebar.nav,
  },
  list: {
    width: theme.dimensions.eventsDrawerWidth,
    paddingLeft: theme.spacing(4),
    flexDirection: 'column',
  },
  toolbarContainer: {
    position: 'sticky',
    top: 0,
  },
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    gap: theme.spacing(1),
  },
  title: {
    flexGrow: 1,
  },
  divider: {
    margin: '0 1.375rem',
  },
}));

const ObdInfoDrawer = ({ open, onClose, deviceId }) => {
  const classes = useStyles();
  const t = useTranslation();

  const obdColumns = ['obdCadence', 'obdTorque', 'obdMotorTemperature', 'obdCellTemperature', 'obdSoc', 'obdOdometer'];
  const posAttributes = useSelector((state) => state.session.positions?.[deviceId]?.attributes);
  const distanceUnit = useAttributePreference('distanceUnit');

  const formatValue = (column, value) => {
    switch (column) {
      case 'obdCadence':
        return value !== 0 ? `${value} rpm` : '---';
      case 'obdCellTemperature':
        return value !== 0 ? formatTemperature(value) : '---';
      case 'obdMotorTemperature':
        return value !== 0 ? formatTemperature(value) : '---';
      case 'obdSoc':
        return value !== 0 ? formatPercentage(value) : '---';
      case 'obdTorque':
        return value !== 0 ? `${value?.toFixed(1)} Nm` : '---';
      case 'obdOdometer':
        return value !== 0 ? formatDistance(value, distanceUnit, t) : '---';

      default:
        return value != null ? value : '---';
    }
  };
  return (
    <Drawer
      hideBackdrop
      disableEnforceFocus
      anchor="right"
      open={open}
      onClose={onClose}
      className={classes.drawer}
      aria-hidden={!open}
      sx={{ width: 'fit-content' }}
    >
      <div className={classes.toolbarContainer}>
        <Toolbar className={classes.toolbar} disableGutters>
          <IconButton onClick={onClose}>
            <ArrowBack />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {t('getMoreObdInfoTitle')}
          </Typography>
        </Toolbar>
        <Divider className={classes.divider} />
      </div>
      <div className={classes.listContainer}>
        <div className={classes.listBlock}>
          <List className={classes.list}>
            <ListItem disableGutters>
              <ListItemText
                primary={t('obdInfoTypeTitle')}
              />
              <ListItemText
                primary={t('obdInfoTypeValue')}
                sx={{ paddingLeft: '120px' }}
              />
            </ListItem>
          </List>
        </div>
        <Divider className={classes.divider} />
        <List className={classes.list}>
          {posAttributes && obdColumns.map((obdcolumn) => (
            <ListItem
              key={obdcolumn}
              secondaryAction={
                (
                  <ListItemText
                    primary={posAttributes[obdcolumn] != null ? formatValue(obdcolumn, posAttributes[obdcolumn]) : '---'}
                    sx={{ paddingRight: '60px' }}
                    edge="end"
                  />
                )
              }
              disableGutters
            >
              <ListItemText
                primary={t(obdcolumn)}
              />
            </ListItem>
          ))}
        </List>
      </div>
    </Drawer>
  );
};

export default ObdInfoDrawer;
